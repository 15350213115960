<template>
  <div id="nova-senha">
    <!-- <aside></aside> -->

    <div id="body">

      <div class="loginArea" v-if="validado">
        <img src="../../assets/logo-eventos.png" class="logo-eventos" alt="EventosSP">
        <span class="text-branco text-bem-vindo">Digite sua nova senha</span>
        <form @submit.prevent="alterar">
          <input id="senha-nova" type="password" placeholder="Nova senha" v-model="senha.senhaNova" />
          <input id="senha-re-nova" type="password" placeholder="Confirme a senha" v-model="senha.senhaNovaConfirmar" v-if="senha.campoConfirmarVisivel"/>
          <button type="submit">Alterar senha</button>
        </form>
      </div>

    </div>
  </div>
</template>




<script>
import api from "../../services/api";
import { msg } from '@/global'

export default {
  props: ["id"],
  name: "AlterarSenha",

  data() {
    return {

      validado: false,

      senha: {
        codigo: null,
        senhaNova: null,
        senhaNovaConfirmar: null,
        campoConfirmarVisivel: false
      }

    };
  },

  created() {

    const token = this.$route.query.id;
    this.verificar(token)
  },

  methods: {

    verificar(token) {

      api
        .post("webcliente/verificar", { codigo: token })
        .then(() => {
          this.validado = true
          this.senha.codigo = token
        })
        .catch(async () => {
          this.validado = false
          msg('Código inválido', 'vermelho', 2000)
          await new Promise(r => setTimeout(r, 500));
          this.$router.push({path: '/login'})
        })

    },

    alterar() {

      api
        .put("webcliente/alterar-senha", this.senha)
        .then(async (res) => {
          
          if (res.status == 200) {

            this.senha.campoConfirmarVisivel = true
            msg('Confirme a senha', 'verde', 5000)

          } else 
          if (res.status == 201) {

            msg('Sua senha foi alterada.', 'verde', 3000)
            await new Promise(r => setTimeout(r, 500));
            this.$router.push({path: '/login'})

          }  

        })
        .catch(err => {

          const erro = err.response.data

          if (erro.msgs) {

            erro.msgs.forEach(item => {
              msg(item, 'vermelho', 5000)
            })

          } 
          else {
            msg(err.response.data, 'vermelho', 3000)
          }

        })

    },

  }

};
</script>




<style scoped>

#nova-senha {
  background-color: #555555;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

aside {
  position: absolute;
  left: 0;
  width: 300px;
  height: 100vh;
  background-color: #17a2b8;
}

span {
  text-align: center;
  color: rgb(59, 52, 52);
  margin-bottom: 14px;
  font-size: 16px;
}


#body {
    display: flex;
    position: absolute;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 100%;
}

#login .loginArea {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 100px;
  max-width: 600px;
  width: 420px;
  height: 330px;
  /* background: #dee4f1; */
  border-radius: 5px;
  position: relative;
  display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

form {
  display: flex;
  flex-direction: column;
  margin: 0px 25px;
}

input {
  margin-bottom: 15px;
  height: 40px;
  width: 300px;
  font-size: 18px;
  padding: 10px;
  outline: none;
  border: 0;
  border-radius: 9px;
  background: rgb(241, 241, 241);
}

button {
  margin-top: 10px;
  height: 40px;
  border: 0;
  border-radius: 50px;
  background: #E7B864;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

/* .loginArea a {
  margin-top: 10px;
  text-decoration: none;
  color: #E7B864;
  font-size: 15px;
  cursor: pointer;
} */

.loginArea {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 100px;
  max-width: 600px;
  width: 420px;
  height: 330px;
}

img {
  height: 50px;
}

.text-branco {
  color: #fff;
  font-size: 25px;
}

.logo-eventos {
  width: auto;
  height: auto;
  width: 70%;
  margin-bottom: 30px;  
  display: flex;
}

</style>
