<template>

    <div id="page-detalhes">
        <!-- <b-card-group columns> -->
        <b-list-group>

            <!-- <h2>Financeiro > Detalhes da escala > Profissionais Escalados</h2> -->
            
            <div>
                <h2>
                    <a class="link-page" @click="toFinanceiro">Financeiro</a> 
                    > 
                    <a class="link-page" @click="toFinanceiroEscalas">Escalas</a>
                    >  Profissionais Escalados
                </h2>
                <b-button class="btnRight" variant="btn btn-warning btn-sg" @click="toFinanceiroEscalas"> Voltar</b-button>
            </div>

            

            <b-card class="card-evento" bg-variant="light" text-variant="black" title="">               
                
                <div>

                    <b-row>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Solicitante " label-for="input-solicitante">
                                <b-form-input id="input-solicitante" size="sm" class="text-uppercase input_fundo" readonly :value="infos.solicitante"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Função" label-for="input-funcao">
                                <b-form-input id="input-funcao" size="sm" class="text-uppercase input_fundo" readonly :value="infos.funcao"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Entrada" label-for="input-entrada">
                                <b-form-input id="input-entrada" size="sm" class="text-uppercase input_fundo" readonly :value="infos.entrada"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Saída" label-for="input-saida">
                                <b-form-input id="input-saida" size="sm" class="text-uppercase input_fundo" readonly :value="infos.saida"></b-form-input>
                            </b-form-group>
                        </b-col>
                        
                    </b-row>

                    <b-row>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Normal" label-for="input-normal">
                                <b-form-input id="input-normal" size="sm" class="text-uppercase input_fundo" readonly :value="infos.hora_normal_cli"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Noturna" label-for="input-normal">
                                <b-form-input id="input-normal" size="sm" class="text-uppercase input_fundo" readonly :value="infos.hora_noturna_cli"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Extra normal" label-for="input-normal"> 
                                <b-form-input id="input-normal" size="sm" class="text-uppercase input_fundo" readonly :value="infos.hora_extra_cli"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Extra noturna" label-for="input-normal">
                                <b-form-input id="input-normal" size="sm" class="text-uppercase input_fundo" readonly :value="infos.hora_extra_noturna_cli"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col sm="6">
                            <b-form-group class="input_fundo" label-cols="12" label-cols-lg="12" label="Comentários e Contestações" label-for="input-comentarios">
                                <div>
                                    <b-form-textarea
                                    size="sm"
                                    disabled
                                    id="textarea-state"
                                    v-model="comentariosContestacoes"
                                    rows="1"
                                    ></b-form-textarea>
                                </div>           
                            </b-form-group>   
                        </b-col>  
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Gestor" label-for="input-gestor">
                                <b-form-input id="input-gestor" size="sm" class="text-uppercase" readonly :value="infos.gestor"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Valor total" label-for="input-gestor">
                                <b-form-input id="input-gestor" size="sm" class="text-uppercase" readonly :value="infos.valor_final"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    
                    <!-- <b-button href="#" variant="info" class="botao">Criar escala</b-button> -->
                <!-- <router-link to="/criar-escala-2" tag="button" class="botao botao-editar" exact>Editar evento</router-link> -->

                </div>

            </b-card>


            <div class="tabela">
                <b-table id="tabela" striped hover :items="infos.profissionais" :fields="fields"></b-table>
            </div> 


            
            
        
        <!-- </b-card-group> -->
        </b-list-group>
    </div>

</template>










<script>
export default {
    props: [ 'infos' ],
    data(){
        return{

            telaOrigem: '',
            dadosHeader: {},
            fields: [
                {key: 'nome', label: 'Nome', formatter: 'nome'},
                {key: 'check_in', label: 'Check-In', formatter: 'check_in'},
                {key: 'check_out', label: 'Check-Out', formatter: 'check_out'},
                {key: 'variacao', label: 'Variação', formatter: 'variacao'},
                {key: 'carga_hora_total', label: 'Carga total', formatter: 'carga_hora_total'},
                {key: 'horas_normais', label: 'Normal', formatter: 'horas_normais'},
                {key: 'horas_extras', label: 'Normal Extra', formatter: 'horas_extras'},
                {key: 'horas_noturnas', label: 'Noturna', formatter: 'horas_noturnas'},
                {key: 'horas_extras_noturnas', label: 'Noturna Extra', formatter: 'horas_extras_noturnas'},
            ],

            comentariosContestacoes: '',
            
            items: [],
        
        }
    },

    created() {

        const local = localStorage.getItem('eventoHeaderEtapa2')
        this.dadosHeader = JSON.parse(local)
        this.comentariosContestacoes = this.dadosHeader.comentarios
        this.telaOrigem = localStorage.getItem('telaOrigem')
        // console.log(this.infos)
    },
    
    methods: {

        toFinanceiro() {
            if (this.telaOrigem == 'financeiroEmAprovacao') {
                this.$router.push({ name: 'financeiro' }) 
            } else {
                this.$router.push({ name: 'financeiroAprovados' }) 
            }
        },

        toFinanceiroEscalas() {  
            this.$router.push({ name: 'financeiroDetalhes' })   
        },

    }


};
</script>






<style scoped>

    .card-evento {
        /* height: 320px; */
        height: auto;
    }

    h2 {
        margin-top: 30px;
        font-size: 25px;
        color: rgb(188, 143, 95);
    }

    .btnRight {
        float: right;
        margin-top: -40px;
        margin-right: 15px;
        margin-bottom: 5px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .tabela {
        margin-top: 25px;
    }

    .link-page {
        font-size: 24px;
        color: rgb(188, 143, 95);  
        cursor: pointer;
    }

    


</style>