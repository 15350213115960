
<template>

  <div class="fundo ">
    <div class="backgra">
      <img
        src="@/assets/logo/salus.png"
        class="img-salus"
        alt="Salus"
      >

      <div class="borda mx-auto col-lg-12">

        <div id="msg">
          <div class="">

            <div class="title-top">
              SOLICITAÇÃO ENVIADA
            </div>

            <div class="title-adesao">
              <span>
                Sua solicitação de adesão foi aceita,<span ><b> enviamos uma mensagem para o e-mail</b></span> informado descrevendo os procedimentos a serem seguidos e os próximos passos. Será um prazer tê-lo conosco em nossa equipe!<span><b> Atenciosamente Departamento de Adesão da {{nomeEmpresa}}</b></span>
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "msg",

  data() {
    return {

      nomeEmpresa: '',

    };
  },

  created() {

    this.nomeEmpresa = localStorage.getItem('nomeEmpresa')
    
  },

  methods: {},
};
</script>


<style  scoped>
.fundo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgra {
  width: 100%;
  background-color: #bc0000;
  height: 240px;
  position: relative;
  z-index: 1;
}

.img-salus {
  margin-top: 1rem;
}

.risco {
  margin-bottom: 1rem;
  border-bottom: 2px solid #a1a1a1;
  width: 100%;
}

.title-top {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  font-family: inherit, sans-serif;
  line-height: 11px;
  color: #ffffff;
}

@media screen and (max-width: 480px) {
  .title-top {
    font-size: 30px;
  }
}

.title-adesao {
  margin-top: 10rem;
  display: flex;
  text-align: justify;
  justify-content: center;
  justify-items: center;
  font-size: 29px;
  font-family: inherit, sans-serif;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 3rem;
  font-family: inherit, sans-serif;
  /* line-height: 11px; */
  color: #0e0d0d;
}

.borda {
  width: 50%;
  margin-top: 1rem;
}

@media screen and (max-width: 480px) {
  .borda {
    width: 100%;
    padding: 5px;
  }
}

@media screen and (max-width: 960px) {
  .borda {
    width: 100%;
    padding: 5px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

</style>