import api from '../services/api'

export default {

    namespaced: true,
    state: {
        isAuthenticated: false,
        token: null,
        user: null,
        isMenuVisible: false,
        loading: false
    },

    mutations: {
        toggleMenu(state, isVisible){
            if(!state.user) {
                state.isMenuVisible = false
                return
            }
            state.isMenuVisible = isVisible
        },
        setUser(state, user){
            //console.log(acao)
            state.user = user
            
            if(user){
                api.defaults.headers.common['Authorization'] = `bearer ${user.token}`;
                state.isMenuVisible = true;
                state.isAuthenticated = true;
            } else {
                delete api.defaults.headers.common['Authorization'];
                state.isMenuVisible = false;
                state.isAuthenticated = false;
            }

        },
        setLoading(state, val){
            state.loading = val
        },
        setAuth(state, val){
            state.isAuthenticated = val
        }
    },

}