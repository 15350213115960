<template>
    <div
        class="backgra"
        :style="cssVars"
    >
        <img
            :src="require('@/assets/logo/' + logo)"
            class="img-salus"
            alt="Salus"
        />

        <div class="borda mx-auto col-lg-12">
            <div id="documentacao">
                <div
                    class="accordion"
                    role="tablist"
                >
                    <b-card
                        no-body
                        class="mb-1"
                    >
                        <div class="title-top">
                            <h3>DETALHES DO CANDIDATO</h3>
                        </div>

                        <b-card-body>
                            <b-form enctype="multipart/form-data">
                                <div class="form-row">

                                    <div class="dadosPessoais">

                                        <div class="title-informacoes">
                                            <h3>Dados Pessoais</h3>
                                        </div>
                                        <div class="form-row">

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Nome Completo</label>
                                                <b-form-input
                                                    id="input-1"
                                                    class="text-uppercase"
                                                    v-model="form.nome"
                                                    onkeyup="return lowerCase(this)"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> E-mail</label>
                                                <b-form-input
                                                    id="input-2"
                                                    type="email"
                                                    v-model="form.email"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Estado Civil</label>
                                                <b-form-select
                                                    v-model="form.estadoCivil"
                                                    :options="estadoCivil"
                                                ></b-form-select>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Nacionalidade</label>
                                                <b-form-select
                                                    v-model="form.nacionalidade"
                                                    :options="paises"
                                                ></b-form-select>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Escolaridade</label>
                                                <b-form-select
                                                    v-model="form.escolaridade"
                                                    :options="escolaridades"
                                                ></b-form-select>
                                            </div>

                                            

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Data de
                                                    Nascimento</label>
                                                <b-form-input
                                                    type="date"
                                                    id="datepicker-dateformat2"
                                                    v-model="form.dataNasc"
                                                    :date-format-options="{
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }"
                                                    locale="pt"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Gênero: </label>
                                                <b-form-select
                                                    v-model="form.sexo"
                                                    :options="sexo"
                                                ></b-form-select>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Raça</label>
                                                <b-form-select
                                                    v-model="form.raca"
                                                    :options="racas"
                                                ></b-form-select>
                                            </div>

                                            <div class="risco"></div>

                                        </div>
                                        <!--dadosPessoais-->
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Dados Complementares--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="dadosComplementares col-lg-12 ">

                                        <h3>Dados Complementares</h3>

                                        <div class="form-row">

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Alguma restricão fisica? Qual?</label>
                                                <b-form-input
                                                    id="input-7"
                                                    v-model="form.restFisica"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Fumante? </label>
                                                <b-form-select
                                                    v-model="form.fumante"
                                                    :options="fumante"
                                                ></b-form-select>
                                            </div>

                                            <div class="risco"></div>
                                        </div>

                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Dados Adicionais--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="DadosAdicionais col-lg-12">
                                        <h3>Dados Adicionais</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-12">
                                                <label><span class="text-danger">*</span> Já trabalhou em outras cooperativas? Quais?</label>
                                                <b-form-textarea
                                                    v-model="form.dadosAdic"
                                                    id="textarea-default"
                                                ></b-form-textarea>
                                            </div>

                                            <div class="risco"></div>

                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--RG e CPF--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="rgCpf col-lg-12">
                                        <h3>RG e CPF</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> RG</label>
                                                <b-form-input
                                                    id="input-8"
                                                    v-model="form.rg"
                                                    v-mask="'##.###.###-#'"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Orgão Emissor</label>
                                                <b-form-input
                                                    id="input-9"
                                                    v-model="form.orgEmissor"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Data de
                                                    Expedição</label>
                                                <b-form-input
                                                    type="date"
                                                    id="datepicker-dateformat2"
                                                    v-model="form.dataExp"
                                                    :date-format-options="{
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }"
                                                    locale="pt"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Número do CPF</label>
                                                <b-form-input
                                                    id="input-10"
                                                    v-model="form.cpf"
                                                    v-mask="'###.###.###-##'"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Documentação Profissional--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="docProf col-lg-12">
                                        <h3>Documentação Profissional</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Atividade profissional: </label>
                                                <b-form-input
                                                    id="input-11"
                                                    v-model="form.nomeFuncao"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Tipo de Registro</label>
                                                <b-form-input
                                                    id="input-11a"
                                                    v-model="form.regProf"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Número</label>
                                                <b-form-input
                                                    id="input-12"
                                                    v-model="form.regNum"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger"></span> Data de
                                                    Vencimento</label>
                                                <b-form-input
                                                    type="date"
                                                    id="datepicker-dateformat2"
                                                    v-model="form.regDataVencimento"
                                                    :date-format-options="{
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }"
                                                    locale="pt"
                                                ></b-form-input>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Inscriçao do PIS--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="inscPis col-lg-12">
                                        <h3>Inscriçao do PIS</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-12">
                                                <label><span class="text-danger">*</span> Número</label>
                                                <b-form-input
                                                    id="input-13"
                                                    v-model="form.pisNum"
                                                    v-mask="'###########'"
                                                ></b-form-input>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Título de Eleitor--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="tituloEleitor col-lg-12">
                                        <h3>Título de Eleitor</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-12">
                                                <label><span class="text-danger">*</span> Número</label>
                                                <b-form-input
                                                    id="input-14"
                                                    v-model="form.tituloNum"
                                                    v-mask="'############'"
                                                ></b-form-input>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Cadastro de Contribuinte Mobiliário - CCM--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="CadCcm col-lg-12">
                                        <h3>Cadastro de Contribuinte Mobiliário - CCM</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-12">
                                                <label><span class="text-danger"></span> Número</label>
                                                <b-form-input
                                                    id="input-15"
                                                    v-model="form.ccmNum"
                                                    v-mask="'######'"
                                                ></b-form-input>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Endereço--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="end col-lg-12">
                                        <h3>Endereço</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Cep</label>
                                                <b-form-input
                                                    id="input-8"
                                                    v-model="form.cep"
                                                    v-mask="'#####-###'"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-5">
                                                <label><span class="text-danger">*</span> Cidade</label>
                                                <b-form-input
                                                    id="input-16"
                                                    v-model="form.cidade"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-1">
                                                <label><span class="text-danger">*</span> UF</label>
                                                <b-form-input
                                                    id="input-17"
                                                    v-model="form.uf"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Bairro</label>
                                                <b-form-input
                                                    id="input-18"
                                                    v-model="form.bairro"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-5">
                                                <label><span class="text-danger">*</span> Logradouro</label>
                                                <b-form-input
                                                    id="input-19"
                                                    v-model="form.endereco"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-1">
                                                <label><span class="text-danger">*</span> Nº</label>
                                                <b-form-input
                                                    id="input-20"
                                                    v-model="form.numEndereco"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger"></span> Complemento</label>
                                                <b-form-input
                                                    id="input-21"
                                                    v-model="form.complemento"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Celular</label>
                                                <b-form-input
                                                    id="input-22"
                                                    v-model="form.celular"
                                                    v-mask="' (##) #####-####'"
                                                    readonly
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger"></span> Celular
                                                    adicional</label>
                                                <b-form-input
                                                    id="input-23"
                                                    v-model="form.celAdic"
                                                    v-mask="' (##) #####-####'"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger"></span> fixo</label>
                                                <b-form-input
                                                    id="input-24"
                                                    v-model="form.fixo"
                                                    v-mask="' (##) ####-####'"
                                                ></b-form-input>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Dados Bancários--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="dadosBancario col-lg-12">
                                        <h3>Dados Bancários</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Banco</label>
                                                <b-form-select
                                                    v-model="form.banco"
                                                    :options="bancos"
                                                ></b-form-select>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Agência</label>
                                                <b-form-input
                                                    id="input-26"
                                                    v-model="form.agencia"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Conta Corrente</label>
                                                <b-form-input
                                                    id="input-27"
                                                    v-model="form.contCorrente"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Digito</label>
                                                <b-form-input
                                                    id="input-28"
                                                    v-model="form.digito"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Tipo Conta</label>
                                                <b-form-select
                                                    v-model="form.tipoConta"
                                                    :options="tipoContas"
                                                ></b-form-select>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger"></span> Chave PIX</label>
                                                <b-form-input
                                                    id="input-30"
                                                    v-model="form.chavePix"
                                                ></b-form-input>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Crédito em Conta de terceiros?--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="creditoTerceiro col-lg-12">
                                        <h3>Crédito em Conta de terceiros?</h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger"></span> Nome Beneficiário</label>
                                                <b-form-input
                                                    id="input-31"
                                                    v-model="form.nomeBen"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger"></span> CPF Beneficiário</label>
                                                <b-form-input
                                                    id="input-32"
                                                    v-model="form.cpfBen"
                                                ></b-form-input>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Conhecimentos Técnicos--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="conhecimentoTec col-lg-12">
                                        <h3>Conhecimentos Técnicos<span class="text-danger"> *</span></h3>

                                        <div class="form-row">
                                            <b-form-group
                                                class="mx-3"
                                                label
                                            ><span class="tecnico">Selecione os items abaixo</span>
                                                <b-form-checkbox-group
                                                    id="checkbox-group-1"
                                                    name="conhecTec"
                                                    v-model="form.selected"
                                                    :options="options"
                                                    switches
                                                    stacked
                                                ></b-form-checkbox-group>
                                            </b-form-group>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Preferência de horário--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="prefHorario col-lg-12">
                                        <h3>Preferência de horário<span class="text-danger"> *</span></h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-5 mx-3">
                                                <b-form-group label="Plantões">
                                                    <b-form-checkbox-group
                                                        id="checkbox-group-2"
                                                        name="plantao"
                                                        v-model="form.selected2"
                                                        :options="options2"
                                                        switches
                                                        stacked
                                                    ></b-form-checkbox-group>
                                                </b-form-group>
                                            </div>

                                            <div class="form-group col-lg-6">
                                                <b-form-group
                                                    label="Procedimentos"
                                                >
                                                    <b-form-checkbox-group
                                                        id="checkbox-group-3"
                                                        name="procedimento"
                                                        v-model="form.selected3"
                                                        :options="options3"
                                                        switches
                                                        stacked
                                                    ></b-form-checkbox-group>
                                                </b-form-group>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Dependentes--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="depen col-lg-12">
                                        <h3>Dependentes<span class="text-danger"> *</span></h3>

                                        <div class="form-row">
                                            <div class="form-group col-lg-6">
                                                <label><span class="text-danger">*</span> Nome Completo</label>
                                                <b-form-input
                                                    id="input-1"
                                                    class="text-uppercase"
                                                    v-model="form.nomeDep"
                                                    onkeyup="return lowerCase(this)"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Data de
                                                    Nascimento</label>
                                                <b-form-input
                                                    type="date"
                                                    id="datepicker-dateformat2"
                                                    v-model="form.dataNascDep"
                                                    :date-format-options="{
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }"
                                                    locale="pt"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> CPF</label>
                                                <b-form-input
                                                    id="input-10"
                                                    v-model="form.cpfDep"
                                                    v-mask="'###.###.###-##'"
                                                ></b-form-input>
                                            </div>

                                            <div class="form-group col-lg-3">
                                                <label><span class="text-danger">*</span> Tipo de dependente </label>
                                                <b-form-select
                                                    v-model="form.tipoDep"
                                                    :options="tipoDep"
                                                ></b-form-select>
                                            </div>

                                            <div class="risco"></div>
                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Foto Perfil--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="foto col-lg-12">
                                        <h3>Foto Perfil</h3>

                                        <div class="form-row">
                                            <b-form-file
                                                v-model="form.file1"
                                                :state="Boolean(file1)"
                                                placeholder="Escolha um arquivo ou solte-o aqui ... "
                                                drop-placeholder="Drop file here..."
                                            ></b-form-file>
                                            <div class="mt-3">Selecione uma imagem: {{ form.file1 ? form.file1.name : '' }}</div>

                                        </div>
                                    </div>
                                    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-- FIM --XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

                                    <div class="enviar col-lg-6">
                                        <div>
                                            <b-button
                                                id="salvarSemEnviar"
                                                type="button"
                                                variant="danger m-2"
                                                @click="salvarSemEnviar"
                                            >Salvar (sem Enviar)</b-button>
                                        </div>

                                        <div>
                                            <b-button
                                                id="buttonEnviar"
                                                type="button"
                                                variant="danger m-2"
                                                @click="insertDoc"
                                            >Enviar</b-button>
                                        </div>
                                    </div>

                                </div>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </div>
            </div>

            <!--MODAL DE CONFIRMAÇÃO-->
            <b-modal
                ref="my-modal"
                hide-footer
                title=""
            >

                <div class="d-block text-center">
                    <h2>Atualmente não atendemos a sua cidade. Confirma seu cadastro?</h2>
                </div>
                <b-button
                    class="mt-3"
                    variant="outline-success"
                    block
                    @click="confirmaAdesao('sim')"
                >Sim</b-button>
                <b-button
                    class="mt-2"
                    variant="outline-danger"
                    block
                    @click="confirmaAdesao('nao')"
                >Não</b-button>
            </b-modal>

            <b-card
                class="mt-3"
                header="Resultado do Form"
            >
                <pre class="m-0">{{ form }}</pre>
            </b-card>
        </div>
    </div>
</template>

<script>
import api from "../../services/api";
import axios from "axios";
export default {
  name: "Documentacao",
  props: ["emp"],

  data() {
    return {
      documentacao: [],

      logo: "salus.png",

      estadoCivil: [
        "SOLTEIRO",
        "CASADO",
        "DIVORCIADO",
        "SEPARADO",
        "VIÚVO"
      ],

      racas: [
        "BRANCA",
        "PRETA",
        "PARDA",
        "AMARELA",
        "INDÍGENA",
        "NÃO INFORMADO"
      ],

      sexo: ["MASCULINO", "FEMININO"],

      fumante: ["SIM", "NÃO"],

      escolaridades: [],
      paises: [],
      bancos: [],

      selected: [], // Must be an array reference!
      options: [
        { text: "Aspiração de Vias Aérias", value: "aspAeria" },
        { text: "Fixação de TQT", value: "fixTqt" },
        {
          text:
            "Identifica Nebulizador, umidificador, concetrador, torpedo de 02",
          value: "indentNeb",
        },
        { text: "Ventilação Mecânica", value: "ventMec" },
        { text: "Cuidado com SNE", value: "cuidSnd" },
        { text: "Cuidado com GTT", value: "cuidGtt" },
        { text: "Manuseio de Bomba de infusão", value: "bombaInf" },
        { text: "Cateter Vesical de Demora", value: "cateterVis" },
        {
          text: "Administração de medicação p/ acesso venoso central",
          value: "admMedVenoso",
        },
        { text: "Medicação de Picc", value: "medPicc" },
        { text: "Punção venosa e medicação periférica", value: "puncVenosa" },
        { text: "Medicação intramuscular", value: "medIntra" },
        { text: "Medicação Subcutânea", value: "medSub" },
        {
          text: "Cuidado com Nutrição Parenteral Prolongada - NPP",
          value: "cuidNutriNpp",
        },
        {
          text: "Prática de banhos(aspersão, leito, imersão",
          value: "pratBanho",
        },
        {
          text: "Verificação de Sinais Vitais e Saturação de 02",
          value: "verSinais02",
        },
        { text: "Verificação de glicemia capilar", value: "verGlicCapilar" },
      ],

      tipoContas: ['CORRENTE','POUPANÇA'],

      selected2: [], // Must be an array reference!
      options2: [
        { text: "Diurno Impar", value: "diuImpar" },
        { text: "Diurno Par", value: "diuPar" },
        { text: "Noturno Impar", value: "notImpar" },
        { text: "Noturno Par", value: "notPar" },
      ],

      selected3: [], // Must be an array reference!
      options3: [
        { text: "Manhã", value: "manha" },
        { text: "Tarde", value: "tarde" },
        { text: "Noite", value: "noite" },
      ],

      tipoDep: [
        { text: "Selecione", value: null },
        "Cônjuge",
        "Filho",
        "Amante",
      ],

      form: {
        nome: "",
        email: "",
        cpf: "",
        estadoCivil: "",
        nacionalidade: "",
        escolaridade: "",
        raca: "",
        dataNasc: "",
        sexo: "",
        restFisica: "",
        fumante: "",
        dadosAdic: "",
        rg: "",
        orgEmissor: "",
        dataExp: "",
        nomeFuncao: "",
        regProf: "",
        numero: "",
        regDataVencimento: "",
        pisNum: "",
        tituloNum: "",
        ccmNum: "",
        cep: "",
        cidade: "",
        uf: "",
        bairro: "",
        endereco: "",
        numEndereco: "",
        complemento: "",
        celular: "",
        celAdic: "",
        fixo: "",
        banco: "",
        agencia: "",
        contCorrente: "",
        digito: "",
        tipoConta: "",
        chavePix: "",
        nomeBen: "",
        cpfBen: "",
        nomeDep: "",
        dataNascDep: "",
        cpfDep: "",
        tipoDep: "",
        file1: "",
        selected: [],
        selected2: [],
        selected3: [],
      },

      show: true,

      dadosEmpresa: null,
      bgColor: "#a1a1a1",
    };
  },

  created() {

      this.getDadosEmpresa()
      this.getSolicitacao()

  },

  methods: {
    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 10000,
        appendToast: true,
      });
    },

    getDadosEmpresa() {
        
        // alert(this.$route.query.emp)
        const empresa = this.$route.query.emp;

        api
        .get("adesao/empresa/" + empresa)
        .then((res) => {
            // console.log(res.data);
            this.dadosEmpresa = res.data;
            this.bgColor = res.data.cor;
            this.logo = res.data.logoPath;
            this.escolaridades = this.dadosEmpresa.escolaridades
            this.paises = this.dadosEmpresa.paises
            this.bancos = this.dadosEmpresa.bancos
            

            if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
        })
        .catch(() => {
            if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
        });

    },

    getSolicitacao() {
        
        // alert(this.$route.query.emp)
        const key = this.$route.query.key;

        api
        .get("adesao/empresa/documentacao/" + key)
        .then((res) => {
            // console.log(res.data);
            this.form = res.data;
            this.form.nacionalidade = 30

            // if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
        })
        .catch(() => { this.$router.push("/adesao/erro") });

    },

    insertDoc(evt) {
      evt.preventDefault();
    },

    buscarCep() {
      // console.log("cep", this.form.cep);

      this.form.endereco = null;
      this.form.bairro = null;
      this.form.cidade = null;
      this.form.uf = null;

      if (this.form.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.form.cep}/json`)
          .then((res) => {
            console.log(res.data);

            const dadosCep = res.data;
            this.form.endereco = dadosCep.logradouro;
            this.form.bairro = dadosCep.bairro;
            this.form.cidade = dadosCep.localidade;
            this.form.uf = dadosCep.uf;
          })
          .catch((err) => this.msg("Erro: cep não encontrado.", err, "danger"));
      }
    },

    getipoReg() {
      api
        .get("adesao/empresa/entidade/" + this.form.atividadeProf)
        .then((res) => {
          this.form.regProf = res.data;
        })
        .catch(() => this.msg("ATENÇÃO", "Erro ao buscar entidade", "danger"));
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    confirmaAdesao(acao) {
      this.$refs["my-modal"].hide();

      if (acao == "sim") {
        this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
      } else {
        api
          .delete(`adesao/${this.dadosEmpresa.id}/${this.form.cpf}`)
          .then(() => {
            this.msg("ADESÃO", "CADASTRADO NÃO CONCLUÍDO.", "warning");
          })
          .catch(() => this.msg("ATENÇÃO", "Erro...", "danger"));
      }
    },
  },

  computed: {
    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--height": this.height + "px",
      };
    },
  },
};
</script>


<style  scoped>
#adesao-cad {
  margin-bottom: 5rem;
}

.backgra {
  width: 100%;
  background-color: var(--bg-color);
  margin-bottom: 227rem;
  height: 240px;
  position: relative;
  z-index: 1;
}

.img-salus {
  margin-top: 1rem;
}

.risco {
  margin-bottom: 1rem;
  margin-top: 15px;
  border-bottom: 2px solid #ca0202;
  width: 100%;
}

.title-top {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-family: inherit, sans-serif;
  line-height: 11px;
  color: #212529;
}

.enviar {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-left: 41%;
}

@media screen and (max-width: 480px) {
  .enviar {
    margin-left: 53%;
  }
}

.borda {
  width: 70%;
  margin-top: 1rem;
}

@media screen and (max-width: 480px) {
  .borda {
    width: 100%;
    padding: 5px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.title-informacoes {
  padding: 15px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1.25rem;
}

#salvarSemEnviar {
  width: 212px;
  border-radius: 25px;
  margin-right: 5px;
  margin-top: 15px;
  cursor: pointer;
  border: 0;
  padding: 10px;
  font-size: 20px;
  transition: all 0.5s;
  outline: none;
}

#buttonEnviar {
  width: 212px;
  border-radius: 25px;
  margin-right: 5px;
  margin-top: 15px;
  cursor: pointer;
  border: 0;
  padding: 10px;
  font-size: 20px;
  transition: all 0.5s;
  outline: none;
}

#input-16,
#input-17,
#input-18,
#input-19,
#input-14m,
#input-22,
#input-1,
#input-2,
#input-10,
#input-11,
#input-11a,
#input-12,
#input-8
 {
  background-color: #fff;
}

.tecnico {
  font-size: 20px;
  font-weight: 700;
  color: #ca0202;
}

.dadosPessoais {
  width: 100%;
}


.dadosComplementares {
  /* display: none; */
}

.DadosAdicionais {
  /* display: none; */
}

.rgCpf {
  /* display: none; */
}

.docProf {
  /* display: none; */
}

.inscPis {
  /* display: none; */
}

.tituloEleitor {
  /* display: none; */
}

.CadCcm {
  /* display: none; */
}

.end {
  /* display: none; */
}

.dadosBancario {
  /* display: none; */
}

.creditoTerceiro {
  /* display: none; */
}

.conhecimentoTec {
  /* display: none; */
}

.prefHorario {
  /* display: none; */
}

.depen {
  /* display: none; */
}

.foto {
  /* display: none; */
}
</style>