<template>
    
    <div id="page-financeiro">
        
        <h2>Financeiro - aprovados</h2>

        <hr>

        <h5>Filtrar por período: </h5>

        <date-picker  
            id="input-periodo"
            v-model="range"
            lang="pt-br" 
            range
            style="width:220px;"
            type="date"
            format="DD/MM/YYYY"
            @change="getEventos"> 
        </date-picker>

        <br>
        <br>

        <b-list-group>
            
            <div class="tabela">
                <b-table 
                striped 
                hover 
                :items="items" 
                class="text-center" 
                :fields="fields" 
                :busy="load"
                ref="selectableTable"
                >

                    <template #table-busy >
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong></strong>
                        </div>
                    </template>

                    <template #cell(Detalhes)="data">
                        <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->                      
                        <b-icon class="icones" icon="info-circle" scale="1.5" style="color: #C1A474" @click="toDetalhes(data.item)"></b-icon>                    
                    </template>  

                    <template #cell(Aprovar)="data">  
                        <b-icon class="icones" icon="check-circle" scale="1.5" variant="info" @click="toAprovacao(data.item)"></b-icon>                    
                    </template>   
                </b-table>
            </div>

        </b-list-group>
    </div>

</template>

<script>
import api from '../../services/api'

// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css' 
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

import { msg } from '@/global'
import dayjs from 'dayjs';

export default {
    name: 'GerenciarEscalas',
    components: { DatePicker },
    data(){
        return{
            dadosHeader: {},
            escalas: [],
            fields: [
                {key: 'solicitante', label: 'Solicitante', formatter: 'solicitante'},
                {key: 'data_evento', label: 'Data Início', formatter: value => {
                    const valores = value.split('-')
                    return valores[2]+'/'+valores[1]+'/'+valores[0]
                }},
                {key: 'nome_evento', label: 'Nome do Evento', formatter: 'nome_evento'},
                {key: 'setor', label: 'Setor', formatter: 'setor'},
                {key: 'valor_solicitado', label: 'Valor solicitado', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                {key: 'valor_final', label: 'Valor final', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                {key: 'data_aprovacao', label: 'Data/Hora de aprovação', formatter: 'data_aprovacao'},
                {key: 'nota_fiscal', label: 'NF Emitida', formatter: 'nota_fiscal'},
                
                'Detalhes',
                // 'Aprovar'
            ],
            items: [],
            range:[],
            load: false
        }
    },

    created() {
        // console.log('criou financeiro')
     
        // var data = new Date();  
        // data.setMonth( data.getMonth() -1 );

        this.range[0] = new Date(dayjs().subtract(30, 'day').format('YYYY-MM-DD'))
        this.range[1] = new Date()

        this.getEventos()

    },

    methods: {

        msg(titulo, texto, cor) {
           this.toastCount++
           this.$bvToast.toast(texto, {
            title: titulo,
            variant: cor,
            solid: true,
            autoHideDelay: 5000,
            appendToast: true
           })
        },
        
        getEventos() {

            console.log(this.range)
            

            const ids = this.getIdClienteUnidade
            // console.log('periodo', this.range)
            // console.log('getEscalas2',`webcliente/financeiro?id_cliente=${ids.idCliente}&id_unidade=${ids.idUnidade}`)

            if(this.range[0] == null && this.range[1] == null) this.range = ['0', '0']

            this.load = true
            api.get(`webcliente/financeiro/aprovados/${ids.idCliente}/${ids.idUnidade}/${this.range[0]}/${this.range[1]}`)
            .then(res => {

                // console.log(res.data)
                this.items = res.data
                this.load = false
            })
            .catch(err => {
                this.load = false
                msg(err.response.data, 'vermelho', 5000)
            })

        },

        toDetalhes(evento) {
            // console.log('evento', evento)
            const idEvento = evento.id_evento
            this.$store.commit('escala/setIdEvento', idEvento)

            const eventoHeaderEtapa2 = {
                solicitante: evento.solicitante,
                nome: evento.nome_evento,
                dataView: evento.data_evento_view,
                data: evento.data_evento,
                setor:{ text: evento.setor },
                status: evento.status,
                endereco: evento.endereco,
                comentarios: evento.obs,
                valorSolicitadoTotal: evento.valor_solicitado,
                valorFinalTotal: evento.valor_final
            }
            localStorage.setItem('telaOrigem', 'financeiroAprovados') 
            localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
            this.$router.push({ name: 'financeiroDetalhes' })  
            
        },

        toAprovacao(evento) {
            // console.log(escala)
            const idEvento = evento.id_evento
            this.$store.commit('escala/setIdEvento', idEvento)

            // const eventoHeaderEtapa2 = {
            //     solicitante: evento.solicitante,
            //     nome: evento.nome_evento,
            //     dataView: evento.data_evento_view,
            //     data: evento.data_evento,
            //     setor:{ text: evento.setor },
            //     endereco: evento.endereco,
            //     comentarios: evento.obs,
            //     valorSolicitadoTotal: evento.valor_solicitado,
            //     valorFinalTotal: evento.valor_final
            // }
            // localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
             
            if(evento.status == 'em aprovação'){
                this.$router.push({ name: 'financeiroAvaliacao' }) 
            } else {
                this.msg('O evento não pôde ser aprovado', 'Ainda há escalas em contestação.', 'warning')
            }
            
        },

        toProfsEscalados(evento) {
            // console.log('escala',escala)
            const idEvento = evento.idEvento
            this.$store.commit('escala/setIdEvento', idEvento)

            const eventoHeaderEtapa2 = {
                solicitante: evento.solicitante,
                nome: evento.nomeEvento,
                dataView: evento.dataView,
                data: evento.dataEvento,
                setor: evento.setor,
                endereco: evento.endereco,
                comentarios: evento.obs,
                valorSolicitadoTotal: evento.valor_solicitado,
                valorFinalTotal: evento.valor_final
            }
            localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
            this.$router.push({ name: 'agendaEscalados' })   
            
        }
    },

    computed: {
        getIdClienteUnidade() {
            return this.$store.state.escala.idClienteUnidade  
        }
    },

    


}
</script>

<style scoped>

    /* #id {
        margin: 30px;
    } */


    #page-financeiro h2 {
        margin-top: 50px;
        margin-bottom: 15px;
        /* color: rgb(70, 81, 82); */
        color: rgb(188, 143, 95);
    }


    .tabela {
        width: 80vw;
    }

    img {
        cursor: pointer;
    }

    span {
        margin-top: 30px;
    }

    .icones {
        cursor: pointer;
    }


    @media (max-width: 700px) {

        .tabela {
            width: 300px;
            overflow:auto; 
    
        }
    }

</style>