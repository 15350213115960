<template>

    <div id="page-detalhes">
        <!-- <b-card-group columns> -->
        <b-list-group>

            <!-- <h2>Financeiro > Detalhes da escala</h2> -->
            

            <div>
                <h2>
                    <a class="link-page" @click="toFinanceiro">Financeiro</a> 
                    > Escalas
                </h2>
                <b-button class="btnRight" variant="btn btn-warning btn-sg" @click="toFinanceiro"> Voltar</b-button>
            </div>


            <b-card class="card-evento" bg-variant="light" text-variant="black" title="">               
                
                <div>

                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Solicitante" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.solicitante"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Nome do Evento" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.nome"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Setor" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.setor.text"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Data" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.dataView"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Endereço" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.endereco"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Comentários" label-for="input-comentarios">
                                <div>
                                    <b-form-textarea
                                    size="sm"
                                    disabled
                                    id="textarea-state"
                                    v-model="textComentarios"
                                    rows="2"
                                    ></b-form-textarea>
                                </div>           
                            </b-form-group>   
                        </b-col>  
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Valor solicitado total" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="valorSolicitadoTotal"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Valor final total" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="valorFinalTotal"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    
                    <!-- <b-button href="#" variant="info" class="botao">Criar escala</b-button> -->
                <!-- <router-link to="/criar-escala-2" tag="button" class="botao botao-editar" exact>Editar evento</router-link> -->

                </div>

            </b-card>


            
            <div id="tabelaAndBotoes" v-if="isTableVisibled">

                <div class="tabela">
                    <b-table striped hover :items="items" class="text-center" :fields="fields" ref="selectableTable">
                        <template v-slot:cell(sel)="row">
                            <b-form-checkbox
                                v-model="row.item.sel"
                                @input="onPreviewClick($event, row.index, row.item)"
                                :options = "items"
                                name="checkbox-1"
                                value="true"
                                unchecked-value="false"
                                >
                            </b-form-checkbox>            
                        </template>
                        

                        <template #cell(botao)="data">
                            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->                      
                            <!-- <b-icon class="icones" icon="info-circle" scale="1.5" variant="info" @click="toDetalhes(data.item)"></b-icon>  -->
                            <b-button variant="outline-primary" @click="toListaEscalados(data.item)">Lista</b-button>              
                        </template>    
                    </b-table>
                </div>


                <div v-if="this.eventoPagamentoAprovado == false">
                    <b-row>
                        <b-col sm="6" class="col-botao">
                            <b-button class="botao" variant="warning" @click="showModalContestacao()">CONTESTAR VALORES</b-button>  
                        </b-col>
                        <b-col sm="6" class="col-botao">
                            <b-button class="botao" variant="success" @click="aprovarPagamento()">APROVAR PAGAMENTO</b-button> 
                        </b-col>
                    </b-row>
                </div>

            </div>


            <!-- <div id="comentsAndContestar" v-else>

                <b-row>
                    <b-col sm="12">
                        <b-form-group label-cols="12" label-cols-lg="12" 
                                        label="Escreva abaixo os detalhes da contestação de valores, descreva o motivo pelo qual gostaria que fosse feita a revisão dos valores." 
                                        label-for="input-comentarios">
                            <div>
                                <b-form-textarea
                                size="sm"
                                id="textarea-state"
                                v-model="textContestacao"
                                rows="8"
                                ></b-form-textarea>
                            </div>           
                        </b-form-group>   
                    </b-col>  
                </b-row>



                <b-row>
                    <b-col sm="6" class="col-botao">
                        <b-button class="botao" variant="danger" @click="cancelarContestarValores()">CANCELAR</b-button>  
                    </b-col>
                    <b-col sm="6" class="col-botao">
                        <b-button class="botao" variant="success" @click="enviarContestar()">ENVIAR CONTESTAÇÃO</b-button> 
                    </b-col>
                </b-row>

            </div> -->

            <div>

                <b-modal id="modal-contestacao" ref="modal-contestacao" hide-footer title="Enviar Contestação">
                    
                    <div>
                        <label><span></span>Escreva aqui a sua contestação: </label>
                    </div>

                    <div>
                        <b-form-textarea
                            class="text-area"
                            rows="4"
                            v-model="textContestacao"
                        ></b-form-textarea>
                    </div>

                    <br>

                    <div class="form-row">
                        
                        <div class="col-lg-6">
                        <b-button class="mt-2" variant="danger" block @click="hideModalContestacao">Cancelar</b-button>
                        </div>

                        <div class="col-lg-6">
                        <b-button class="mt-2" variant="success" block @click="enviarContestar" link to="/solicitacoes">Enviar Contestação</b-button>
                        </div>

                    </div>
                </b-modal>

            </div>
           

            
        
        <!-- </b-card-group> -->
        </b-list-group>
    </div>

</template>










<script>
import api from '../../services/api'
import { msg } from '@/global'

export default {
    data(){
        return{

            telaOrigem: '',
            dadosHeader: {},
            row: [],
            isTableVisibled: true,
            valorSolicitadoTotal: null,
            valorFinalTotal: null,
            eventoPagamentoAprovado: false,
            // fields: ['Função','Setor','Funcao','Qtd','Entrada','Saída','carga total','Orcamamento'],

            fields: [
                {key: 'sel', label: '', formatter: 'sel'},
                {key: 'solicitante', label: 'solicitante', formatter: 'solicitante'},
                {key: 'funcao', label: 'Função', formatter: 'funcao'},
                {key: 'quantidade', label: 'Qtd', formatter: 'quantidade'},
                {key: 'entrada', label: 'Início', formatter: 'entrada'},
                {key: 'saida', label: 'Saída', formatter: 'saida'},
                {key: 'carga_total', label: 'Carga total', formatter: 'carga_total'},
                {key: 'gestor', label: 'Gestor', formatter: 'gestor'},
                {key: 'valor_solicitado', label: 'Valor solicitado', formatter: value => {
                    // const valor = parseFloat(value)
                    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                {key: 'valor_final', label: 'Valor final', formatter: value => {
                    // const valor = parseFloat(value)
                    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                {key: 'statusContestacao', label: 'Contestado?', formatter: 'statusContestacao'},
                {key: 'botao', label: 'Profs.', formatter: 'valor_final'}
                
            ],
            
            items: [],
            textComentarios: '',
            textContestacao: ''
        }
    },

    methods: {

        toFinanceiro() {

            if (this.telaOrigem == 'financeiroEmAprovacao') {
                this.$router.push({ name: 'financeiro' }) 
            } else {
                this.$router.push({ name: 'financeiroAprovados' }) 
            }
        },

        toListaEscalados(escala) {
            // console.log(escala)
            this.$router.push({ name: 'financeiroEscalados', params: { infos: escala } })   
        },

        onPreviewClick(value, index, item) {
            //console.log(value, index, item)
            
            if(item.sel == 'true'){
                this.row.push(item)
            } else {
                this.row.splice(index, 1)
            }

            //console.log(this.row)
        
        },

        showModalContestacao() {
            if(this.row.length < 1) {
                msg('Selecione ao menos uma escala', 'vermelho', 3000)
                return
            } 
            
            this.$refs['modal-contestacao'].show()

            // this.isTableVisibled = false
        },

        hideModalContestacao() {
 
            this.$refs['modal-contestacao'].hide()

        },

        cancelarContestarValores() {
            this.isTableVisibled = true
        },

        enviarContestar() {
            const idEvento = this.$store.state.escala.idEvento 
        
            var ids = []
            this.items.forEach(item => {
                if(item.sel == 'true') ids.push(item.id_escala)
            })

            api.post(`webcliente/financeiro/contestar-escala`, { idEvento: idEvento, id_escalas: ids, comentarios: this.textContestacao})
            .then(() => {

                msg('Contestação enviada com sucesso.', 'verde', 3000)
                this.$router.push ({name: 'financeiro'})
                this.$refs['modal-contestacao'].hide()

            })
            .catch(err => msg(err.response.data, 'vermelho', 4000))

            this.isTableVisibled = true
        },

        aprovarPagamento() {

            const idEvento = this.$store.state.escala.idEvento 
            var ids = []
            this.items.forEach(item => {
                ids.push(item.id_escala)
            })

            api.post(`webcliente/financeiro/aprovar_eventos`, { id_evento: idEvento, id_escalas: ids})
            .then(() => {

                this.isTableVisibled = true
                this.$router.push({ name: 'financeiroMensagem', params: {ids: ids} })  

            })
            .catch(err => msg(err.response.data, 'vermelho', 4000))  

        },
        
        mostrarSels() {
            console.log(this.row)
        },

        limparSels() {
            this.row = []

            this.items.forEach(item => {
                item.sel = false
            })
        },

        verificaStatusEvento() {
            if (this.dadosHeader.status == 'pagamento aprovado') this.eventoPagamentoAprovado = true
            // console.log(this.eventoPagamentoAprovado)
        }
    },

    created() {

        const local = localStorage.getItem('eventoHeaderEtapa2')
        this.dadosHeader = JSON.parse(local)
        this.textComentarios = this.dadosHeader.comentarios
        this.telaOrigem = localStorage.getItem('telaOrigem')
        // console.log('this.dadosHeader', this.dadosHeader)
        console.log('telaOrigem', this.telaOrigem)

        const idEvento = this.$store.state.escala.idEvento 

        this.verificaStatusEvento()

        // console.log(idEvento)

        api.get(`webcliente/financeiro/detalhes?id_evento=${idEvento}`)
        .then(res => {

            // console.log('resssssss', res.data)
            this.valorSolicitadoTotal = res.data[0].valor_solicitado
            this.valorFinalTotal = res.data[0].valor_final
            this.items = res.data[0].escalas.map(item => {
                const escala = item
                // console.log('escala', escala)
                escala.sel = false
                return escala
            })
        })
        .catch(err => msg(err.response.data, 'vermelho', 400))


    },

};
</script>






<style scoped>

    .link-page {
        font-size: 24px;
        /* color: rgb(249, 144, 97);    */
        color: rgb(188, 143, 95);
        cursor: pointer;
    }

    h2 {
        margin-top: 30px;
        font-size: 24px;
        color: rgb(188, 143, 95);
    }

    .btnRight {
        float: right;
        margin-top: -40px;
        margin-right: 15px;
        margin-bottom: 5px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .col-botao {
        display: flex;
        justify-content: center;
    }

    .botao {
        /* padding: 12px 70px; */
        width: 400px;
        padding: 11px;
        margin-top: 20px;
        margin-bottom: 70px;
    }

    .input_fundo {
        background:white;
        /* font-weight: bold; */
    }

    #tabelaAndBotoes, #comentsAndContestar {
        margin-top: 40px;
    }
    



</style>