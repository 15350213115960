
<template>
  <div class="backgra" :style="cssVars">
    <img
      :src="require('@/assets/logo/' + logo)"
      class="img-salus"
      alt="Salus"
    />

    <div class="borda mx-auto col-lg-12">
      <div id="adesao-cad">
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <div class="title-top">
              <h3>FORMULÁRIO DE ADESÃO</h3>
            </div>

            <b-card-body>
              <b-form
                enctype="multipart/form-data"
              >
                <div class="form-row">
                  <div class="title-informacoes">
                    <h3>Dados Pessoais</h3>
                  </div>

                  <div class="col-lg-12"></div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> Nome</label>
                    <b-form-input
                      id="input-1"
                      class="text-uppercase"
                      v-model="form.nome"
                      onkeyup="return lowerCase(this)"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> CPF</label>
                    <b-form-input
                      id="input-2"
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> E-mail</label>
                    <b-form-input
                      id="input-3"
                      type="email"
                      v-model="form.email"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label
                      ><span class="text-danger">*</span> Confirmação de
                      e-mail</label
                    >
                    <b-form-input
                      id="input-4"
                      type="email"
                      v-model="form.confEmail"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-4">
                    <label><span class="text-danger">*</span> Celular</label>
                    <b-form-input
                      id="input-5"
                      v-model="form.celular"
                      v-mask="' (##) #####-####'"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-4">
                    <label
                      ><span class="text-danger"></span> Celular
                      adicional</label
                    >
                    <b-form-input
                      id="input-6"
                      v-model="form.celAdic"
                      v-mask="' (##) #####-####'"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-4">
                    <label><span class="text-danger"></span> fixo</label>
                    <b-form-input
                      id="input-7"
                      v-model="form.fixo"
                      v-mask="' (##) #####-####'"
                    ></b-form-input>
                  </div>

                  <div class="risco"></div>

                  <div class="page-informacoes col-lg-12">
                    <h3>Endereço</h3>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> Cep</label>
                    <b-form-input
                      id="input-8"
                      v-model="form.cep"
                      v-mask="'#####-###'"
                      @keyup="buscarCep()"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> Cidade</label>
                    <b-form-input
                      id="input-9"
                      v-model="form.cidade"
                      readonly
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-1">
                    <label><span class="text-danger">*</span> UF</label>
                    <b-form-input
                      id="input-10"
                      v-model="form.uf"
                      readonly
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-5">
                    <label><span class="text-danger">*</span> Bairro</label>
                    <b-form-input
                      id="input-11"
                      v-model="form.bairro"
                      readonly
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> Logradouro</label>
                    <b-form-input
                      id="input-12"
                      v-model="form.endereco"
                      readonly
                    ></b-form-input>
                  </div>

                  <div class="risco"></div>

                  <div class="page-informacoes col-lg-12">
                    <h3>Atividade Profissional</h3>
                  </div>

                  <div class="form-group col-lg-3">
                    <label
                      ><span class="text-danger">*</span> Atividade
                      profissional:
                    </label>
                    <b-form-select
                      v-model="form.atividadeProf"
                      :options="ativProf"
                      @change="getEntidade"
                    ></b-form-select>
                  </div>

                  <div class="form-group col-lg-3">
                    <label
                      ><span class="text-danger">*</span> Registro
                      Profissional</label
                    >
                    <b-form-input
                      id="input-14"
                      v-model="form.regProf"
                      readonly
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-3">
                    <label
                      ><span class="text-danger">*</span> Nº de Registro</label
                    >
                    <b-form-input
                      id="input-13"
                      v-model="form.regNum"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-3">
                    <label
                      ><span class="text-danger"></span> Data de
                      Vencimento</label
                    >
                    <b-form-input
                      type="date"
                      id="datepicker-dateformat2"
                      v-model="form.regDataVencimento"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      locale="pt"
                    ></b-form-input>
                  </div>

                  <div class="enviar col-lg-12">
                    <b-button
                      id="buttonEnviar"
                      type="button"
                      variant="danger m-3"
                      @click="insertAdesao"
                      >Enviar</b-button
                    >
                  </div>

                  <!-- <input type="file" accept="assets/fotos/*" capture="camera"> -->



                  <!-- <b-form-file
                    v-model="form.file1"
                    :state="Boolean(file1)"
               
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <div class="mt-3">Selected file: {{ file1 ? file1.name : '' }}</div> -->

                </div>
              </b-form>
            </b-card-body>
          </b-card>
        </div>
      </div>



      <!--MODAL DE CONFIRMAÇÃO-->
      <b-modal ref="my-modal"
               hide-footer title="">

        <div class="d-block text-center">
          <h2>Atualmente não atendemos a sua cidade. Confirma seu cadastro?</h2>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="confirmaAdesao('sim')">Sim</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click="confirmaAdesao('nao')">Não</b-button>
      </b-modal>


      <!-- <b-card class="mt-3" header="Resultado do Form">
        <pre class="m-0">{{ form }}</pre>
      </b-card> -->
    </div>
  </div>
</template>

<script>
import api from "../../services/api";
import axios from "axios";
export default {
  name: "AdesaoCad",
  props: ["emp"],

  data() {
    return {
      adesao: [],
      titulo: "Nova Adesão",

      logo: "salus.png",

      ativProf: [],

      form: {
        nome: "",
        cpf: "",
        email: "",
        confEmail: "",
        celular: "",
        celAdic: "",
        fixo: "",
        cep: "",
        cidade: "",
        uf: "",
        bairro: "",
        endereco: "",
        atividadeProf: null,
        regProf: "",
        regNum: "",
        regDataVencimento: null
      },
     
      show: true,

      dadosEmpresa: null,
      bgColor: "#a1a1a1",
    };
  },

  created() {

    // alert(this.$route.query.emp)
    const empresa = this.$route.query.emp

    api
      .get("adesao/empresa/" + empresa)
      .then((res) => {
        // console.log(res.data);
        this.dadosEmpresa = res.data;
        this.bgColor = res.data.cor;
        this.logo = res.data.logoPath;
        this.ativProf = res.data.funcoes;

        if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
      })
      .catch(() => {
        if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
        //this.msg("ERRO AO BUSCAR", err.response.data, "danger")
      });

  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 10000,
        appendToast: true
      });
    },


    insertAdesao(evt) {
      evt.preventDefault();


      this.form.idEmpresa = this.dadosEmpresa.id
      this.form.nomeEmpresa = this.dadosEmpresa.nomeEmpresa
      this.form.diasReprovado = this.dadosEmpresa.diasReprovado
      
      api
        .post("adesao", this.form)
        .then((res) => {

          console.log(res.data);
          if (res.data == 'ok') {
            this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
          } else 
          if (res.data == 'ok?') {
            this.$refs['my-modal'].show()
            return
          } 

          this.form = '';
          localStorage.setItem('nomeEmpresa', this.dadosEmpresa.nomeEmpresa)
          this.$router.push('/msg');

        })
        .catch((err) =>
          this.msg("ATENÇÃO!", err.response.data, "danger")
        );

    },


    buscarCep() {
      // console.log("cep", this.form.cep);

      this.form.endereco = null
      this.form.bairro = null
      this.form.cidade = null
      this.form.uf = null

      if (this.form.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.form.cep}/json`)
          .then((res) => {
            console.log(res.data);

            const dadosCep = res.data;
            this.form.endereco = dadosCep.logradouro;
            this.form.bairro = dadosCep.bairro;
            this.form.cidade = dadosCep.localidade;
            this.form.uf = dadosCep.uf;
          })
          .catch((err) => this.msg("Erro: cep não encontrado.", err, "danger"));
      }

    },

    getEntidade() {
      
      api
      .get('adesao/empresa/entidade/' + this.form.atividadeProf)
      .then((res) => {
        
        this.form.regProf = res.data
      })
      .catch(() => this.msg("ATENÇÃO", 'Erro ao buscar entidade', 'danger'));

    },


    hideModal() {
      this.$refs['my-modal'].hide()
    },
    confirmaAdesao(acao) {
      
      this.$refs['my-modal'].hide()

      if (acao == 'sim') {
        this.msg('ADESÃO', 'CADASTRADO COM SUCESSO.', 'success');
      } else {
        
        api.delete(`adesao/${this.dadosEmpresa.id}/${this.form.cpf}`)
        .then(() => {
          this.msg('ADESÃO', 'CADASTRADO NÃO CONCLUÍDO.', 'warning');
        })
        .catch(() => this.msg("ATENÇÃO", 'Erro...', 'danger'));

      }

    },


  },
  

  computed: {
    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--height": this.height + "px",
      };
    },
  },
};
</script>


<style  scoped>

#adesao-cad {
  margin-bottom: 5rem;
}
.backgra {
  width: 100%;
  background-color: var(--bg-color);

  height: 240px;
  position: relative;
  z-index: 1;
}

.img-salus {
  margin-top: 1rem;
}

.risco {
  margin-bottom: 1rem;
  border-bottom: 2px solid #a1a1a1;
  width: 100%;
}

.title-top {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-family: inherit, sans-serif;
  line-height: 11px;
  color: #212529;
}

.enviar {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-left: 41%;
}

@media screen and (max-width: 480px) {
  .enviar {
    margin-left: 53%;
  }
}

.borda {
  width: 70%;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 480px) {
  .borda {
    width: 100%;
    padding: 5px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.title-informacoes {
  padding: 15px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1.25rem;
}

#buttonEnviar {
  width: 150px;
  border-radius: 25px;
  margin-right: 5px;
  margin-top: 15px;
  cursor: pointer;
  border: 0;
  padding: 6px;
  font-size: 20px;
  transition: all 0.5s;
  outline: none;
}

#input-12, #input-11, #input-10, #input-9, #input-14 {
  background-color: #fff;
}

</style>