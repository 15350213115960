// window.postMessage('','*')

const { default: messageDialogHelper } = require("./message-dialog-helper")

self.onmessage = function (e) {
    if (e.data.dev) {
        console.log(e);
        // messageDialogHelper.displayConfirmMessage('Title', 'Text Test');
        let listDate = ['2024-11-17','2024-11-18','2024-11-19','2024-11-20'];
        messageDialogHelper.displaySuccessMess(`Criado os seguintes Eventos: \n\n${listDate.map(item => `-> ${item}`).join('\n\n')}`);
    }
}