<template>
  <div class="custom-table">
    <!-- <span class="table-total-result" v-if="totalRows >= 1">{{totalRows}} resultados encontrado</span> -->
    
      <!-- :suppressScrollY="true" -->
      <!-- :suppressScrollX="true" -->
        <!-- mode="remote" -->
      <vue-good-table
        styleClass="vgt-table bordered"
        theme="black-rhino"
        :columns="fields"
        :rows="items"
        :rtl="false"
        :isLoading.sync="isLoading"
        :search-options="{
          enabled: true,
          trigger: 'enter',
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        max-height="600px"
        :fixed-header="fixedHeader"
      >
        <!-- :fixed-header="true" -->
        <!-- @on-row-click="$refs.menu.open" -->
        
        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <!-- <span @contextmenu.prevent="$refs.menu.open"> -->
          <span>
            <span v-if="props.column.type === 'avatar'">
              <b-avatar :src="props.row[props.column.field]" />
            </span>

            <span v-else-if="props.column.type === 'phone'">
              {{ getPhoneFormat(props.row[props.column.field]) }}
            </span>
            <span v-else-if="props.column.type === 'boolean'">
              <b-badge
                :variant="getActiveBadge(props.row[props.column.field])"
              >
                {{ getActiveBadgeLabel(props.row[props.column.field]) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.type === 'custom_date'">
              {{ getDateFormat(props.row[props.column.field]) }}
            </span>
            <span v-else-if="props.column.type === 'thumb'">
              <img :src="props.row[props.column.field]" />
            </span>

            <span v-else-if="props.column.type === 'object'">
              {{ getDataObject(props.row[props.column.field], props.column.object_field, props.column.object_field_type, props.column.object_in_object_field) }}
            </span>

            <span v-else-if="props.column.type === 'array'" >
              <span>{{ getDataArray(props.row[props.column.field], props.column.object_field, props.column.object_field_type, props.column.object_in_object_field) }}</span>
              <!-- <span
                v-for="(val, index) in props.row[props.column.field]"
                :key="index"
              >
                {{ getDataObject(val, props.column.object_field, props.column.object_field_type) }},
              </span> -->
            </span>

            <!-- Column: Action -->
            <!-- <span v-else-if="props.column.field === 'asdasdasdasd'"> -->
            <span v-else-if="props.column.field === 'action_buttons'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="onEdit(props.row)" v-if="hasEditButton">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onDelete(props.row)" v-if="hasDeleteButton">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Deletar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onSync(props.row)" v-if="hasSyncButton">
                    <feather-icon icon="RefreshCwIcon" class="mr-50" />
                    <span>Sincronizar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onResend(props.row)" v-if="hasResendButton">
                    <feather-icon icon="SendIcon" class="mr-50" />
                    <span>Reenviar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="openTemplate(props.row)" v-if="hasOpenTemplateButton">
                    <feather-icon icon="Maximize2Icon" class="mr-50" />
                    <span>Abrir template</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <!-- <span v-else :class="{'table-custom-total-row': isTotalRowClass(props.column.field, props.formattedRow[props.column.field])}"> -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>

          </span>

          
            <!-- <vue-context ref="menu">
            <li>
              <b-link @click="onEdit(props.row)" v-if="hasEditButton">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Editar</span>
              </b-link>
              <b-link @click="onDelete(props.row)" v-if="hasDeleteButton">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Deletar</span>
              </b-link>
            </li>
          </vue-context> -->

        </template>

        <div slot="emptystate">
          Nenhum registro encontrado
        </div>
      </vue-good-table>

      <!-- pagination -->
      <b-card-body class="d-flex justify-content-between flex-wrap pt-2" v-if="hasPages">
        <b-col md="4"></b-col>
        
        <b-col md="4">
          <b-form-group
            label="Registro por página"
            label-cols="5"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="limit"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="7" v-if="hasPages">
          <!-- pagination -->
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="limit"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <!-- <span>Exibindo {{limit}} de {{totalRows}} registros</span> -->
        <!-- <span v-if="totalRows > 1">{{totalRows}} resultados encontrado</span> -->
      </b-card-body>
  </div>
</template>

<script>
import {
  BCardBody,
  BDropdown,
  BDropdownItem,
  //   BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormSelect,
  // BLink
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import AppConstants from "@/appConstants";
// import convertDatetimeHelper from "@/helpers/convert-datetime-helper";
import { formatDate } from "@/utils/filter";
// import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import VueContext from 'vue-context';

export default {
  components: {
    // VueContext,
    // BLink,
    // VuePerfectScrollbar,
    BCardBody,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    // BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    BAvatar,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
  },
  props: {
    fields: {
      type: Array,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => {},
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
    },
    displayLoading: {
      type: Boolean,
      default: false,
    },
    hasEditButton: Boolean,
    hasDeleteButton: Boolean,
    hasSyncButton: Boolean,
    hasResendButton: Boolean,
    hasOpenTemplateButton: Boolean,
    hasPages: Boolean,
    fixedHeader: Boolean,
  },
  data() {
    return {
      phoneMask: {
        phone: true,
        phoneRegionCode: "BR",
      },
      pageOptions: [],
      currentPage: 1,
      currentRowDetail: null,
      searchTerm: '',
      rows: [],
      isLoading: false
    };
  },
  created() {
    this.pageOptions = AppConstants.PAGINATOR_DEFAULT_PAGE_SIZE_OPTIONS;
  },
  methods: {
    isTotalRowClass(field) {
      console.log('[isTotalRowClass]', field)
      return false;
    },
    onRowClick() {
      this.$refs.menu.open;
    },
    getLabelField(item, rowFields) {
      const index = rowFields.findIndex((r) => r.key == item);
      if (index >= 0) {
        return rowFields[index].label;
      }
      return item;
    },
    getDataArray(rowList, field, fieldType, objectInObjectField) {
      return rowList.map(item => this.getDataObject(item, field, fieldType, objectInObjectField)).join(', ');
    },
    getDataObject(data, field, fieldType, objectInObjectField) {
      if (!data) {
        return '';
      }
      if (fieldType) {
        if (fieldType == 'custom_date') {
          return this.getDateFormat(data[field]);
        }
        if (fieldType == 'boolean') {
          return this.getActiveBadgeLabel(data[field]);
        }
      }
      if (objectInObjectField) {
        if (data[field]) {
          return data[field][objectInObjectField];
        }
        return data[field];
      }
      if (field) {
        return data[field];
      }
      if (data.name) {
        return data.name;
      }
      return data;
    },
    getPhoneFormat(phone) {
      return phone;
    },
    getDateFormat(dt) {
      // return convertDatetimeHelper.convertStringToDateTime(dt);
      return formatDate(dt, {
        dateStyle: "short",
        timeStyle: "short",
      });
    },
    getActiveBadgeLabel(active) {
      return active ? 'Sim' : 'Não';
    },
    getActiveBadge(active) {
      if (active) {
        return "light-success";
      }
      return "light-danger";
    },
    handleChangePage(page) {
      console.log("page: ", page);
      this.$emit("changePage", page);
    },
    getObjectKeys(object) {
      return Object.keys(object);
    },
    onRowClicked(row) {
      // console.log(row);
      this.$set(row, "_showDetails", !row._showDetails);
      if (this.currentRowDetail) {
        if (this.currentRowDetail.id != row.id) {
          if (this.currentRowDetail._showDetails) {
            this.$set(
              this.currentRowDetail,
              "_showDetails",
              !this.currentRowDetail._showDetails
            );
          }
        }
      }
      this.currentRowDetail = row;
    },
    onEdit(data) {
      console.log("onEdit: ", data);
      this.$emit("onEdit", data);
    },
    onDelete(data) {
      console.log("onDelete: " + data);
      this.$emit("onDelete", data);
    },
    onResend(data) {
      console.log("onResend: " + data);
      this.$emit("onResend", data);
    },
    openTemplate(data) {
      console.log("openTemplate: " + data);
      this.$emit("openTemplate", data);
    },
    onSync(data) {
      console.log("onSync: " + data);
      this.$emit("onSync", data);
    },
    onSortChange(params) {
      console.log(`the user ordered:  ${JSON.stringify(params)}`)
      this.$emit("onSortChange", params[0]);
    },
    onColumnFilter(params) {
      // console.log('onColumnFilter params: ', params);
      this.$emit('onColumnFilter', params.columnFilters)
    }
  },
};
</script>

<style lang="scss">
  .table-custom-total-row {
    background-color: #d9d9d9;
    font-weight: bold;
    // width: 132%;
    // height: 100%;
    // text-align: center;
    // margin: -13px;
    // padding: 10px;
  }
</style>