<template>
    <div id="message">

        <b-card class="card-etapa" bg-variant="" text-variant="black">

            <h2>Pagamento aprovado</h2>
            
            <div class="content">
                <strong>
                    O pagamento foi aprovado, já está pronto para ser <br>processado e estará presente no próximo faturamento.<br><br>
                    Por favor, avalie sua experiência com a Eventos SP, pois apesar de não obrigatório, nos ajuda a identificar e mapear problemas, para estarmos sempre melhorando.
                </strong>


                <b-row>
                    <b-col sm="6" class="col-botao">
                        <b-button class="botao" variant="warning" @click="pularAvaliacao()">Pular Avaliação</b-button>  
                    </b-col>
                    <b-col sm="6" class="col-botao">
                        <b-button class="botao" variant="success" @click="avaliar()">Avaliar</b-button> 
                    </b-col>
                </b-row>
            </div>

        </b-card>

    </div>
</template>

<script>
export default {
    name: 'Mensagem',
    props: ['ids'],
    methods: {
        pularAvaliacao() {
            this.$router.push({ name: 'financeiro' })   
        },
        avaliar() {
            this.$router.push({ name: 'financeiroAvaliacao', params: {ids: this.ids} }) 
        }

    }
}
</script>

<style scoped>


    .card-etapa {
        margin-top: 80px;
        height: 370px;
        width: 700px;
        color: white;
        background-color: yellowgreen;

        display: flex;
        position: relative;
    }

    h2 {
        display: flex;
        justify-content: center;
    }

    .content{
        bottom: 0;
        left: 0;
        border-radius: 7px;
        color: #2b4247;
        background-color: white;
        height: 78%;
        display: flex;
        flex-direction: column;
        position: absolute;   
    }

    .content strong{ 
        padding: 20px;
        padding-bottom: 20px;
        align-items: center;
        text-align: center;
        /* font-size: 24px; */
    }


    .botao {
        border-radius: 50px; 
        padding: 7px 35px;
        margin-top: 30px;
        /* background: yellowgreen; */
        color: #FFF;;
        outline: none;
        border: yellowgreen;
    }

    .botao:hover{
        background: rgb(122, 170, 25);   
    }
    /* .botao:active{
        background: yellowgreen;
    } */

    .col-botao {
        display: flex;
        justify-content: center;
    }

    .botao {
        width: 300px;
        padding: 11px;
    }


    @media (max-width: 700px) {
        
        .card-etapa {
            margin-top: 80px;
            height: 350px;
            width: 80vw;
        }

        .botao {
        margin-top: 10px;
    }

    }

</style>