import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './config/router'
import store from './stores/global'
// import './config/messages'
// import { showError, marcos } from '@/global'

// import core styles
require('@core/scss/core.scss')
// import assets styles
// require('@/assets/scss/style.scss')
require('@/assets/scss/custom-style.scss');

//IMPORT FONT AWELSOME
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import FeatherIcon from './components/FeatherIcon.vue'

Vue.component('feather-icon', FeatherIcon);

library.add(faFilePdf) 


Vue.component('font-awesome-icon', FontAwesomeIcon)
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import "vue-select/dist/vue-select.css";

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2)
import 'sweetalert2/dist/sweetalert2.min.css';

require('./utils/communication-receive');

Vue.config.productionTip = false
// Vue.config.devtools = true

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')