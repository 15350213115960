<template>
    
    <div id="page-agenda">
        <b-list-group>
            <div>
                <h2>Gerenciar Agenda</h2>
                <b-button class="btnRight" variant="btn btn-info btn-sg" @click="getEscalas"> Atualizar lista</b-button>
            </div>

            <div class="next-events">
                <h5>Próximos eventos</h5>
                <div>
                    <b>Total dos pedidos:</b> {{totalBudgetStr}}
                </div>
            </div>  

            <div class="tabela">
                <b-table 
                    id="my-table"
                    striped 
                    hover 
                    class="text-center" 
                    ref="selectableTable" 
                    :items="items" 
                    :fields="fields" 
                    :busy="load"
                    small
                    outlined>

                    <template #table-busy >
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong></strong>
                        </div>
                    </template>


                    <template #cell(Detalhes)="data">
                        <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->                      
                        <b-icon class="icones" icon="info-circle-fill" scale="1.5"  style="color: #C1A474" @click="toDetalhes(data.item)"></b-icon>                    
                    </template>  
                    <template #cell(Escala)="data">  
                        <b-icon class="icones" icon="search" scale="1.5"  style="color: #C1A474" @click="toProfsEscalados(data.item)"></b-icon>                    
                    </template>   
                </b-table>
            </div>

        </b-list-group>
    </div>

</template>

<script>
import api from '../../services/api'
import { msg } from '@/global'

export default {
    name: 'GerenciarEscalas',
    data(){
        return{
            dadosHeader: {},
            escalas: [],
            fields: [
                {key: 'solicitante', label: 'solicitante', formatter: 'solicitante'},
                {key: 'dataEvento', label: 'data Início', formatter: value => {
                    const valores = value.split('-')
                    return valores[2]+'/'+valores[1]+'/'+valores[0]
                }},
                {key: 'nomeEvento', label: 'Nome do Evento', formatter: 'nomeEvento'},
                {key: 'setor.text', label: 'Setor', formatter: 'setor'},
                {key: 'status', label: 'Status', formatter: 'status'},
                {key: 'orcamento', label: 'Orçamento', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                'Detalhes',
                'Escala'
            ],
            items: [],
            load: false,
            totalBudget: 0,
            totalBudgetStr: '',
        }
    },
    methods: {
        getEscalas() {

            this.load = true

            const ids = this.getIdClienteUnidade

            api.get(`webcliente/agenda?id_cliente=${ids.idCliente}&id_unidade=${ids.idUnidade}`)
            .then(res => {

                this.load = false

                this.escalas = res.data

                const escalas = res.data.map(item => {
                    const escala = {
                        idEvento: item.id_evento,
                        solicitante: item.nome_solicitante, 
                        dataEvento: item.data_evento, 
                        dataView: item.data_evento_view, 
                        nomeEvento: item.nome_evento, 
                        setor: {
                            value: 1,
                            text: item.nome_setor
                        }, 
                        status: item.status,
                        obs: item.obs,
                        endereco: item.endereco,
                        orcamento: item.orcamento
                    }
                    this.totalBudget = this.totalBudget + parseFloat(item.orcamento);
                    return escala
                });
                this.items = escalas;
                this.totalBudgetStr = this.totalBudget.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            })
            .catch(err => {
                this.load = false
                msg(err.response.data, 'vermelho', 5000)
            })

        },
        toDetalhes(escala) {
            console.log(escala)
            const idEvento = escala.idEvento
            this.$store.commit('escala/setIdEvento', idEvento)

            const eventoHeaderEtapa2 = {
                solicitante: escala.solicitante,
                nome: escala.nomeEvento,
                dataView: escala.dataView,
                data: escala.dataEvento,
                setor: escala.setor,
                endereco: escala.endereco,
                comentarios: escala.obs
            }
            localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
            this.$router.push({ name: 'agendaDetalhes' })   
            
        },
        toProfsEscalados(escala) {
            console.log(escala)
            const idEvento = escala.idEvento
            this.$store.commit('escala/setIdEvento', idEvento)

            const eventoHeaderEtapa2 = {
                solicitante: escala.solicitante,
                nome: escala.nomeEvento,
                dataView: escala.dataView,
                data: escala.dataEvento,
                setor: escala.setor,
                endereco: escala.endereco,
                comentarios: escala.obs
            }
            localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
            this.$router.push({ name: 'agendaEscalados' })   
            
        }
    },

    computed: {
        getIdClienteUnidade() {
            return this.$store.state.escala.idClienteUnidade  
        }
    },

    created() {
        this.getEscalas()
    },


}
</script>

<style scoped>

    /* #id {
        margin: 30px;
    } */

    .btnRight {
        float: right;
        margin-top: -40px;
        margin-right: 15px;
    }


    #page-agenda h2 {
        margin-top: 50px;
        margin-bottom: 15px;
        /* color: rgb(70, 81, 82); */
        color: rgb(188, 143, 95);
    }

    #page-agenda h5 {
        /* margin: 20px;
        margin-bottom: 15px; */
        color: rgb(70, 81, 82);
    }

    .tabela {
        width: 80vw;
    }

    img {
        cursor: pointer;
    }

    span {
        margin-top: 30px;
    }

    .icones {
        cursor: pointer;
    }

    table.b-table[aria-busy='true'] {
        opacity: 0.6;
    }


    @media (max-width: 700px) {

        .tabela {
            width: 300px;
            overflow:auto; 
    
        }
    }

    .next-events {
        margin: 20px;
        margin-bottom: 15px;
        color: rgb(70, 81, 82);
        display: flex;
        justify-content: space-between;
    }

</style>