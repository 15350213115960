<template>
  <div id="dashboard">
    <b-card class="filter" title="Período Consultado">
      <b-row>
        <b-col cols="2">
          <b-form-group label="De" label-for="input-date-from">
            <date-picker
              v-model="dateFrom"
              type="date"
              format="DD/MM/YYYY"
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Até" label-for="input-date-to">
            <date-picker
              v-model="dateTo"
              type="date"
              format="DD/MM/YYYY"
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Status" label-for="input-status">
            <b-form-select
              v-model="status"
              :options="statusList"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Cliente" label-for="input-status">
            <b-form-select
              v-model="currentClient"
              :options="clientList"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Unidade" label-for="input-status">
            <b-form-select
              v-model="currentUnit"
              :options="unitList"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="3"></b-col> -->
        <b-col cols="2">
          <!-- size="sm" -->
          <b-button class="mt-1" variant="primary" @click="loadData">
            Pesquisar
            <feather-icon icon="SearchIcon" class="" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay
      :show="displayLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row>
        <b-col cols="3" v-for="(item, index) in dataSector" :key="index">
          <b-card
            class="card-data-sector cursor-pointer"
            @click="openSectorDetail(item)"
          >
            <div class="card-flex">
              <div>
                <b-card-title :style="`color: ${item.color}`">{{
                  item.name
                }}</b-card-title>
                <div>{{ item.prof_amount }} Profissionais</div>
                <div>{{ getValueFormat(item.total) }}</div>
              </div>
              <feather-icon icon="UsersIcon" class="" />
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-card>
            <div class="card-flex">
              <div class="">
                <b-card-title :style="`color: #b400b5;text-transform: uppercase;`"
                  >Total de Profissionais</b-card-title
                >
                {{ totalProf }} Profissionais
              </div>
              <feather-icon icon="UserIcon" class="" />
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card>
            <div class="card-flex">
              <div>
                <b-card-title :style="`color: #127d00;`"
                  >TOTAL DE CUSTOS</b-card-title
                >
                {{ getValueFormat(totalCost) }}
              </div>
              <feather-icon icon="DollarSignIcon" class="" />
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" v-for="(item, index) in profList" :key="index">
          <label for="" style="font-weight: 700">{{ item.profissao }}</label>
          <b-progress show-value class="">
            <b-progress-bar
              :style="`background-color: ${item.color}`"
              :value="item.amount"
            ></b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>
    </b-overlay>


    <b-modal id="modal-selector-data" ref="modal-selector-data" hide-footer hide-header size="lg">
      <b-card>
        <div class="card-flex">
          <div>
            <b-card-title :style="`color: ${modalData.color}`">{{
              modalData.name
            }}</b-card-title>
            <div>{{ modalData.prof_amount }} Profissionais</div>
            <div>{{ getValueFormat(modalData.total) }}</div>
          </div>
          <feather-icon icon="UsersIcon" class="" />
        </div>
      </b-card>
      <b-card>
        <div v-for="(item, index) in modalData.items" :key="index" style="border-bottom-style: inset;padding: 1px;font-size: small;">
          <h4>{{item.data_evento}} | {{item.nome}}</h4>
          <div>Evento: {{item.nome_evento}}</div>
          <div>Profissao: {{item.profissao}}</div>
          <div>Check-in: {{item.check_in}} | Check-out: {{item.check_out}}</div>
          <div>Valor total: {{item.valorTotalStr}}</div>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>



<script>
// import Header from "../components/Header";
// import VueApexCharts from "vue-apexcharts";
import { msg } from "@/global";
import reportService from "@/services/reportService";
import {
  BCard,
  BCardTitle,
  BButton,
  BOverlay,
  BFormGroup,
  BFormSelect,
  BRow,
  BCol,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import { generateNewColor } from "@/utils/utils";

export default {
  components: {
    // apexchart: VueApexCharts,
    DatePicker,
    BCard,
    BCardTitle,
    BButton,
    BOverlay,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BProgress,
    BProgressBar,
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",
      status: null,
      statusList: [
        { value: null, text: "Todos" },
        { value: 1, text: "Aprovados" },
        { value: 2, text: "Em aberto" },
      ],
      displayLoading: false,
      dataSector: [],
      totalProf: 0,
      totalCost: 0,
      totalCostStr: "",
      profList: [],
      clientList: [],
      unitList: [],
      currentClient: null,
      currentUnit: null,
      isInitValClientId: false,
      modalData: {}
    };
  },
  created() {
    this.isInitValClientId = true;
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.dateFrom = firstDay;
    this.dateTo = lastDay;
    // this.loadData();

    this.clientList = [{value: null, text: ''},...this.clientesUnidades.map((item) => {
      return { value: item.id, text: item.nome_comercial };
    })];

    const ids = this.getIdClienteUnidade;
    const cli = this.clientesUnidades.find(item => item.id == ids.idCliente);
    if (cli) {
      this.currentClient = cli.id;
      if (this.currentClient && ids) {
        const unit = cli.unidades.find(item => item.id == ids.idUnidade);
        if (unit) {
          this.currentUnit = unit.id;
        }
      }
    }
    // if (this.headerSelectedClientId) {
    // }
  },
  watch: {
    currentClient(val) {
      if (!this.isInitValClientId) {
        this.currentUnit = null;
      }
      this.isInitValClientId = false;
      this.unitList = [];
      if (val) {
        const selectedClient = this.clientesUnidades.find(item => item.id == val);
        if (selectedClient && selectedClient.unidades) {
          this.unitList = [{value: null, text: ''},...selectedClient.unidades.map(item => {
            return {value: item.id, text: item.nome_unidade}
          })];
        }
      }
    }
  },
  computed: {
    headerSelectedClientId() {
      return this.$store.state.selectedClientId;
    },
    getIdClienteUnidade() {
      return this.$store.state.escala.idClienteUnidade;
    },
    clientesUnidades() {
      return this.$store.state.clientesUnidades;
    },
    searchParams() {
      const params = new URLSearchParams();
      // const ids = this.getIdClienteUnidade
      // params.append('scaleClosed', true);
      if (this.currentClient) {
        params.append('id_cliente', this.currentClient);
      }
      if (this.currentUnit) {
        params.append('id_unidade', this.currentUnit);
      }

      // params.append("id_cliente", 7);
      // params.append('id_unidade', 24);
      const dataIni = dayjs(this.dateFrom).format("YYYY-MM-DD");
      const dataFim = dayjs(this.dateTo).format("YYYY-MM-DD");
      params.append("data_ini", dataIni);
      params.append("data_fim", dataFim);
      params.append("dashboard", true);
      if (this.status) {
        // params.append("status", this.status);
        params.append("dashboard_status", this.status);
      }
      // params.append('data_ini', '2023-09-18');
      // params.append('data_fim', '2023-09-22');
      // console.log('searchParams', this.dateRange);

      return params;
    },
  },
  methods: {
    openSectorDetail(item) {
      this.modalData = item;
      this.$refs['modal-selector-data'].show();
    },
    closeModal() {
      this.modalData = {};
      this.$refs['modal-selector-data'].hide();
    },
    getValueFormat(item) {
      const val = parseFloat(item);
      return val.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    loadData() {
      if (!this.dateTo || !this.dateFrom) {
        msg("Favor selecione uma data válida", "vermelho", 5000);
        return;
      }
      if (!this.currentClient) {
        msg("Favor selecione um cliente", "vermelho", 5000);
        return;
      }
      this.displayLoading = true;
      reportService
        .getDashboard(this.searchParams)
        .then((res) => {
          this.totalProf = res.totalProf;
          this.totalCost = res.totalCost;
          this.totalCostStr = res.totalCostStr;
          this.dataSector = res.dataSector.map((item) => {
            item.color = generateNewColor();
            return item;
          });
          this.profList = res.profList.map((item) => {
            item.color = generateNewColor();
            return item;
          });
          this.displayLoading = false;
        })
        .catch((err) => {
          this.displayLoading = false;
          if (err.response && err.response.data) {
            console.log(err.response.data);
            msg(err.response.data.error, "vermelho", 5000);
          }
        });
    },
  },
};
</script>



<style scoped lang="scss">
#dashboard {
  // height: 91vh;
  width: 90vw;
  /* background-color: aqua; */
  padding: 35px;

  .filter {
    .custom-select {
      // width: 80%;
      height: 2.2rem;
    }
    .mx-datepicker {
      width: auto;
    }
  }

}
#dashboard .card .card-flex svg.feather,
#modal-selector-data .card .card-flex svg.feather {
  width: 60px;
  height: 60px;
  stroke: #6e6b7b57;
}

.centralizado {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
}
.card-data-sector.card .card-title {
  margin-bottom: 0px;
}
.card-flex {
  display: flex;
  justify-content: space-between;
}
</style>