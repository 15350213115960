import Vue from 'vue'
import Toasted from 'vue-toasted'

export function showError(e) {
    if (e && e.response && e.response.data) {
        Vue.toasted.global.defaultError({ msg: e.response.data })
    } else 
    if (typeof e === 'string') {
        Vue.toasted.global.defaultError({ msg: e })
    } else {
        Vue.toasted.global.defaultError({ msg: e })
    }
}

export function msg(texto, cor, tempo) {

    Vue.use(Toasted, {
        iconPack : 'material',
        theme: "toasted-primary", 
        position: "top-right", 
        fullWidth: false
    })

    Vue.toasted.show(texto, {
        icon: cor == 'verde' ? 'check' : 'error',
        duration : tempo,
        type: cor == 'verde' ? 'success' : 'error'
    });

}


export default { showError, msg }