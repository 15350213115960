
export default {

    namespaced: true,
    state: {
        idClienteUnidade: {},
        idEvento: null,
        listEventIds: [],
        listCreatedEvents: [],
    },
    mutations: {
        setIdClienteUnidade(state, valor){
            state.idClienteUnidade = valor
        },
        setIdEvento(state, valor){
            state.idEvento = valor
        },
        setListEventIds(state, valor){
            state.listEventIds = valor
        },
        setListCreatedEvents(state, valor){
            state.listCreatedEvents = valor
        },
    }
 
}
