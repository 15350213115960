<template>

    <div id="page-detalhes">
        <!-- <b-card-group columns> -->
        <b-list-group>

            <!-- <h2>Detalhes da escala</h2> -->
            <div>
                <h2>
                    <a class="link-page" @click="toAgenda()">Gerenciar agenda</a> 
                    >  Detalhes da escala
                </h2>
                <b-button class="btnRight" variant="btn btn-warning btn-sg" @click="toAgenda()"> Voltar</b-button>
            </div>


            <b-card class="card-evento" bg-variant="light" text-variant="black" title="">               
                
                <div>

                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Solicitante:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.solicitante"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Nome do Evento:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.nome"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Setor:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.setor.text"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Data:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.dataView"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Endereço:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.endereco"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col sm="12">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Comentários:" label-for="input-comentarios">
                                <div>
                                    <b-form-textarea
                                    disabled
                                    id="textarea-state"
                                    v-model="textComentarios"
                                    rows="2"
                                    ></b-form-textarea>
                                </div>           
                            </b-form-group>   
                        </b-col>  
                    </b-row>

                    
                    <!-- <b-button href="#" variant="info" class="botao">Criar escala</b-button> -->
                <!-- <router-link to="/criar-escala-2" tag="button" class="botao botao-editar" exact>Editar evento</router-link> -->

                </div>

            </b-card>



           



            <div class="tabela">
                <b-table striped hover :items="items" :fields="fields"></b-table>
            </div> 
            
        
        <!-- </b-card-group> -->
        </b-list-group>
    </div>

</template>










<script>
import api from '../../services/api'
import { msg } from '@/global'

export default {
    data(){
        return{
            dadosHeader: {},
            // fields: ['Função','Setor','Funcao','Qtd','Entrada','Saída','carga total','Orcamamento'],

            fields: [
                {key: 'solicitante', label: 'solicitante', formatter: 'solicitante'},
                {key: 'funcao', label: 'Função', formatter: 'funcao'},
                {key: 'quantidade', label: 'Qtd', formatter: 'quantidade'},
                {key: 'entrada', label: 'Entrada', formatter: 'entrada'},
                {key: 'saida', label: 'Saída', formatter: 'saida'},
                {key: 'carga_total', label: 'Carga Horária', formatter: 'carga_total'},
                {key: 'gestor', label: 'Gestor', formatter: 'gestor'},
            ],
            
            items: [],
            textComentarios: ''
        }
    },

    created() {
        const local = localStorage.getItem('eventoHeaderEtapa2')
        this.dadosHeader = JSON.parse(local)
        this.textComentarios = this.dadosHeader.comentarios

        const idEvento = this.$store.state.escala.idEvento 

        api.get(`webcliente/agenda/detalhes?id_evento=${idEvento}`)
        .then(res => {

            // console.log('resssssss',res.data)
            this.items = res.data
            // console.log(this.items)
        })
        .catch(err => msg(err.response.data, 'vermelho', 400))


    },

    methods: {

        toAgenda() {  
            this.$router.push({ name: 'agenda' })   
        },

    }

};
</script>






<style scoped src="./Detalhes.css">


</style>